import React, { Component } from 'react';
import { isLoggedIn } from "../utils/auth";
//import Parser from 'html-react-parser';
import styled from 'styled-components';
import { Link, graphql, navigate } from "gatsby";
import Header from '../components/Header';
import Layout from "../components/layout";
import { StaticImage } from "gatsby-plugin-image";

class OurWork extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.filterSelection = this.filterSelection.bind(this);
    this.w3AddClass = this.w3AddClass.bind(this);
    this.w3RemoveClass = this.w3RemoveClass.bind(this);
    this.btnActive = this.btnActive.bind(this);
  }

  
  handleClick(event) {
    this.filterSelection(event.currentTarget.dataset.cat); 
    this.btnActive();
  }  

  filterSelection = (c) => {
    var x, i;
    x = document.getElementsByClassName("showcase-item");
    if (c === "filter-all") c = "";
    for (i = 0; i < x.length; i++) {
      this.w3RemoveClass(x[i], "show");
      if (x[i].className.indexOf(c) > -1) this.w3AddClass(x[i], "show");
    }

    const classShown = document.getElementsByClassName("show").length;
    const noResultsItem = document.getElementById("no-items");
    if (typeof(noResultsItem) != 'undefined' && noResultsItem != null)
    {
      // exists. 
      if(classShown === 0){
        noResultsItem.className = "";
      }
      else {
        noResultsItem.className =   "is-hidden";
      } 
    }     
  }
 
  w3AddClass = (element, name) => {
    var i, arr1, arr2;
    arr1 = element.className.split(" ");
    arr2 = name.split(" ");
    for (i = 0; i < arr2.length; i++) {
      if (arr1.indexOf(arr2[i]) === -1) {element.className += " " + arr2[i];}
    }
  }

  w3RemoveClass = (element, name) => {
    var i, arr1, arr2;
    arr1 = element.className.split(" ");
    arr2 = name.split(" ");
    for (i = 0; i < arr2.length; i++) {
      while (arr1.indexOf(arr2[i]) > -1) {
        arr1.splice(arr1.indexOf(arr2[i]), 1);     
      }
    }
    element.className = arr1.join(" ");
  }

  componentDidMount() {    
    //this.setState({ authenticated: isAuthenticated() }); 
    this.filterSelection("filter-all");   
    this.btnActive();
  }

  componentDidUpdate() {
    this.filterSelection("filter-all"); 
    this.btnActive();
  }
  
  btnActive() {
    const btns = document.getElementsByClassName("filter-btn");
    const current = document.getElementsByClassName("active");
    for (var i = 0; i < btns.length; i++) {
      btns[i].addEventListener("click", function(){       
        current[0].className = current[0].className.replace(" active", "");
        this.className += " active";      
        });
    }
  }

  render() {
    const isBrowser = typeof window !== `undefined`
    if (!isLoggedIn()) {
      if (!isBrowser) return false
        // If we’re not logged in, redirect to the home page.
        navigate(`/sign-in`)
        return null
    }    

    const excerpt = (str, length, ending) => {
      if (length == null) {
        length = 80;
      }
      if (ending == null) {
        ending = '...';
      }
      if (str.length > length) {
        return str.substring(0, length - ending.length) + ending;
      } else {
        return str;
      }
    };
    
    const data = this.props.data;    
    const regex = /(<([^>]+)>)/ig;
    //const bgLocal = this.props.data.BgImage.childImageSharp.fluid;
    const showcasePostsFilter = data.allWpService.edges.map(({node} ) => {
      return ( 
        <Filter key={node.termTaxonomyId} id={`filter-`+ node.termTaxonomyId}     className="filter-btn" data-cat={`filter-`+ node.slug} onClick={this.handleClick}> 
          <span>{node.name}</span>
        </Filter>
      );
    });   
  
    const showcasePosts = data.allWpShowcaseItem.nodes.map(( node ) => {     
      //const sharp = node.featuredImage.node.localFile.childImageSharp.fluid.src; 
      //const src = getSrc(node.featuredImage.node.localFile.childImageSharp.fluid.src);
      const src = {fluid: node.featuredImage?.node?.localFile?.childImageSharp?.fluid }
      console.log(src);

      const services = node.spotlight.servicesTax.map((service) => {
        return (` filter-`+service.slug );
      }).join('');
      const serviceLabels = node.spotlight.servicesTax.map((service, k) => {    
        return  (
          <em key={k}>{(k ? ', ' : '') + service.name}</em>
        )
      });
      return (
        <Post key={node.id} className={`showcase-item `+services }>
          <Link to={'/our-work/' + node.slug + '/'} className="item-wrapper">
            <span className="item-image">
              {src && 
                <img src={src.fluid.src} alt={node.title}/>
              }
              <span className="item-services">{serviceLabels}</span>
            </span>
            <span className="item-text">
              <span className="item-title" dangerouslySetInnerHTML={{ __html: node.title }}/>
              <span className="item-desc" dangerouslySetInnerHTML={{__html: excerpt(node.excerpt.replace(regex, ''))}}/>
            </span>
            <span className="read-more">Read more &gt;</span>          
          </Link>      
        </Post>       
      );
    });
    
    return (
      <Layout className="page-wrapper">
        <Header/>
        <PageWrapper>         
           <ContentWrapper>
          <Subtitle>We're proud to call it</Subtitle>
          <Title>Our Work</Title>
          <Description>Brief intro into our breadth of services and something about using the button filters below to narrow down the results by service lines</Description>
          <FilterWrapper id="filter-container">
          <Filter key="0" id="filter-0" className="filter-btn active" data-cat="filter-all" onClick={this.handleClick}><span>All</span></Filter>
            {showcasePostsFilter}
          </FilterWrapper>
          <p id="no-items" className="is-hidden"> No results found for this service line yet. Please check back soon.</p>
          <CardsWrapper>        
            {showcasePosts}
          </CardsWrapper>        
        </ContentWrapper>
        <StaticImage 
          src="../assets/work-bg.jpg"
          className="bgImage"
          alt="We're proud to call it Our Work"

          />             
        </PageWrapper>        
      </Layout>
    );
  }
}

export default OurWork;

export const pageQuery = graphql`   
query showcasePostsQuery {
  allWpService {
    edges {
      node {
        name
        slug
        termTaxonomyId
      }      
    }      
  }  
  allWpShowcaseItem(sort: {fields: date, order: DESC}, limit: 1000) {
    nodes {
      id
      slug
      title
      content
      excerpt
      date
      modified
      status
      featuredImage {        
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
              fluid(maxWidth: 600){
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      spotlight {
        theirObjectiveOurChallenge
        ourExpertiseCollaborativeSolutions
        theDifference
        isFeatured
        industriesTax {
          name
          id
          slug
          termTaxonomyId
        }
        solutionsTax {
          id
          slug
          name
          termTaxonomyId
        }
        servicesTax {
          id
          name
          slug
          termTaxonomyId
        }
        logo {
          localFile {
            childImageSharp {
              fluid(maxWidth: 200){
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        backgroundImage {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1600) {
                ...GatsbyImageSharpFluid
              }              
            }
          }
        }
      }
    }
    totalCount
  }
}
`;

const PageWrapper = styled.div`
  text-align: center;
  background-color: #e1e6e9;
  padding-bottom: 30px;
  min-height: 101vh;  
  z-index: 0;
  .bgImage {
    background-color: #e1e6e9;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    position: fixed!important;
    z-index:0;
  }
`
const ContentWrapper = styled.div`
  width: 100%;
  max-width: 80%;
  margin: 0 auto;
  overflow: hidden;
  z-index: 1;
  position: relative;
  #no-items.is-hidden {
    transition: all 1s ease-in-out;    
    transform: translateY(600%); 
    position: relative;
    left: 0;     
    margin: 0;
    overflow: hidden;    
    opacity: 0;
    height: 0;
  }
  #no-items.is-visible {
    opacity: 1;
    transform: translateY(0);
    overflow: visible;
    height: auto;
  }
  @media (max-width: 1440px) {
    max-width: 97%;
  }
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 20px;
  }
`

const Title = styled.h2`
  font-size: 45px;
  color: #FF8674;
  font-weight: 900;
  @media (max-width: 767px) {
    font-size: 45px;
}
`
const Subtitle = styled.h3`
  font-size: 24px;
  color: #333F48;
  margin-bottom: 0;
  text-transform: uppercase;
  font-weight: 600;
  @media (max-width: 991px) {
    font-size: 18px;
  }
`
const Description = styled.p`
  width: 50%;
  margin: 0 auto 20px auto;
  letter-spacing: 1px;
  @media (max-width: 991px) {
    width: auto;
  }
`
const CardsWrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  list-style: none;
  width: 100%;
  margin: 0 auto;
  padding: 0;
`
const FilterWrapper = styled.div`
display: flex;
flex-wrap: wrap;
justify-content: center;
align-items: stretch;
width: 100%;
margin: 2rem auto 0 auto;
padding: 0;
`
const Filter = styled.button`
  padding: 0 0.4rem;
  min-width: 160px;
  margin: 0 0 2.3rem 0;
  border: 0;
  background: transparent none;
  font-size: 13px;
  cursor: pointer;
  span { 
    display: block;
    border: 1px solid #333F48;
    padding: 10px 20px;
    color: #333F48;
    font-weight: 500;
    text-transform: uppercase;    
    &:hover {
      background: #FF8674;
    }    
  }
  &.active {
    span {
       background: #FF8674;
    }
  } 
  @media (max-width: 1100px) {
    min-width: 140px;
  }
  @media (max-width: 991px) {
    margin: 0 0 1.5rem 0;
    padding: 0 0.2rem;
  }
  @media (max-width: 767px) {
    min-width: 0;
    width: 50%;
    padding: 0 0.4rem;
  }
  @media (max-width: 765px)
  and (min-width: 480px) {
    min-width: 0;
    width: 33.3333%;
    padding: 0 0.4rem;
  }     
`
const Post = styled.li`  
  flex: 0 0 auto;
  padding: 0;
  margin-bottom: 2rem;  
  transition: all 0.5s ease-in-out;
  position: relative;
  left: 0;
  top: 0;
  transform: scale(0,0);
  height:0;
  width: 0;
  overflow: hidden;
  a, span {
    display: block;
  }
  a {
    height: 100%;
    background: #F5F5F5;
    color: #425563;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start; 
    align-items: flex-start;   
    text-align: center;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.19);
  }
  .item-image { 
    flex: 1 0 auto;
    position: relative;
    width: 100%;  
    img {
      max-width: 100%;
      height: auto;
    }  
    .item-services {
      position: absolute;
      bottom: 0;
      left: 0;
      color: #ffffff;
      font-style: normal;
      background: #333f48;
      z-index: 2;
      padding: 2px 4px;
      font-size: 10px;
      text-transform: uppercase;
      letter-spacing: 0.1rem;
      em {
        font-style: normal;
      }
    }
  }
  .item-text {
    flex: 1 0 auto;
    padding: 1rem;
    text-align: left;
    width: 100%;   
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
    align-items: flex-start;   
  }
  .item-desc {
     align-self: flex-end;
     position:relative;
     padding-top: 2rem;
     width: 100%;
     &:before {
      content: '';
      display: block;
      position: absolute;
      top: 1rem;
      left: 0;
      width: 50px;
      height: 4px;
      background: #FF8674;
    }
  }
  .item-title {
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;   
    position: relative;
    text-align: left;
    max-width: 100%;
  }
  .read-more {
    flex: 0 0 auto;
    width: auto;
    display: inline-block;
    max-width: 100%;
    background: #FF8674;
    border: 2px solid #FF8674;
    font-weight: 600;
    padding: 10px 25px;
    text-transform: uppercase;
    margin: 0 auto 1rem auto;
    text-align: center;    
    align-self: flex-end;
    cursor: pointer;
    &:hover {
      background: none;
      border: 2px solid #FF8674;
    }
  } 
  &.show {
    padding: 0 15px;
    height:auto;
    flex: 0 0 25%;
    max-width: 25%;    
    transform: none;
    overflow: visible;
  }
  @media (max-width: 991px) {
    &.show {
      flex: 0 0 50%;
      max-width: 50%; 
    }
  }
  @media (max-width: 575px) {
    &.show {  
      padding: 0 8px;   
      flex: 0 0 100%;
      max-width: 100%; 
    }
  }
`